import { useState } from 'react'

import { AuthContext } from '~/context/AuthContext'
import useDetectThirdPartyCookies from '~/hooks/useDetectThirdPartyCookies'
import usePersistedClientConfig from '~/hooks/usePersistedClientValues'

interface AuthProviderProps {
  children: React.ReactNode
  hasPremium?: boolean
  isFreeTrialEligible?: boolean
  thirdPartyCookiesSupportedOverride?: boolean
}

/**
 * Use AuthProvider to handle auth state
 */
const AuthProvider = ({ children, thirdPartyCookiesSupportedOverride }: AuthProviderProps) => {
  const thirdPartyCookiesSupported = useDetectThirdPartyCookies(thirdPartyCookiesSupportedOverride)
  const [hasAccountStateOverride, setHasAccountStateOverride] = useState<boolean>(false)

  // read local client values and set client state
  usePersistedClientConfig()

  return (
    <AuthContext.Provider
      value={{
        hasAccountStateOverride,
        setHasAccountStateOverride,
        hasPremium: true,
        isFreeTrialEligible: false,
        thirdPartyCookiesSupported,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
