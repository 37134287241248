import { createContext } from 'react'

interface AuthContextValues {
  hasAccountStateOverride: boolean
  setHasAccountStateOverride: (hasAccountStateOverride: boolean) => void
  hasPremium: boolean
  isFreeTrialEligible: boolean
  thirdPartyCookiesSupported: boolean
}

export const AuthContext = createContext<AuthContextValues>({
  hasAccountStateOverride: false,
  setHasAccountStateOverride: () => null,
  hasPremium: true,
  isFreeTrialEligible: false,
  thirdPartyCookiesSupported: false,
})
