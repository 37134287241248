import { makeVar, ReactiveVar } from '@apollo/client'

import makeVarPersisted from '~/utils/makeVarPersisted'

const userInitialValue: LumosUser = {
  username: '',
  id: 0,
  authExpiration: 0,
  programExpiresAt: '2099-01-01T12:00:00.000Z',
}

export const currentUserVar: ReactiveVar<LumosUser> = makeVar<LumosUser>(userInitialValue)

export const LOCKED_LOCALE_VAR_NAME = 'lockedLocale'
export const lockedLocaleVar: ReactiveVar<string | null> = makeVarPersisted<string | null>(null, LOCKED_LOCALE_VAR_NAME)
